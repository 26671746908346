<script>
  export default {
    props: ["_class", "settings"],
    data: function() {
      return {
        modal: false,
        modalID: this.$global.fastID(),
        modalClass: "",
      };
    },
    methods: {
      elements: function(el) {
        const elmns = {
          collapse: document.querySelector(`#modal-${this.modalID}`),
          card: document.querySelector(`#modal-${this.modalID} .modal-card`),
          custom: document.querySelector(el),
        };
        return elmns[el] || elmns["custom"];
      },
      open: function() {
        this.modal = true;
        this.elements("body").classList.add("onmodal");
        this.elements("body").style.overflow = "hidden";
        this.$emit("open");
      },
      close: async function() {
        this.modalClass = "closing";

        if (this.modalSettings.closeDelay) {
          await this.sleep(this.modalSettings.closeDelay);
        }

        this.modal = false;
        this.elements("body").classList.remove("onmodal");
        this.$emit("close");
      },
    },
    computed: {
      modalSettings: function() {
        return { ...this.settings };
      },
    },
    destroyed: function() {
      const countModals = document.querySelectorAll(".modal-view");
      if (countModals.length < 1) {
        this.elements("body").classList.remove("onmodal");
      }
      this.elements("body").style.overflow = "scroll";
    },
    mounted: function() {
      this.$emit("mounted");
      if (this.modalSettings.opened) {
        this.open();
      }
    },
  };
</script>

<template>
  <div :class="`modal modal-view${_class ? ' ' + _class : ''} ${modalClass}`" v-if="modal" :id="`modal-${this.modalID}`">
    <div class="modal-content">
      <div class="modal-overlay" v-if="modalSettings.outClose" @click="close"></div>
      <div class="modal-card" :noclose="modalSettings.noclose">
        <div class="modal-card-header" v-if="modalSettings.header !== false">
          <div class="modal-card-header-left">
            <slot name="header"></slot>
          </div>
          <div class="modal-card-header-right" v-if="!modalSettings.noclose">
            <slot name="header-right"></slot>
            <a class="button" @click="close"><iconic name="times"/></a>
          </div>
        </div>
        <div class="modal-card-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal {
    @include Fixed();
    z-index: 1000;
    &-logo {
      color: $primary-color;
      font-size: 200%;
      margin: $mpadding/2 0 $mpadding $mpadding;
    }
    &-content {
      @include Fixed();
      display: grid;
      padding: $space-20;
      background-color: var(--bg-overlay);
      overflow-y: auto;
      @media (max-width: 430px) {
        height: 100%;
      }
    }
    &-overlay {
      @include Fixed();
      height: 100%;
    }
    &-card {
      background-color: #fff;
      border-radius: $mradius;
      width: 100%;
      max-width: 280px;
      margin: auto;
      &-header {
        @include Flex(inherit, space-between, inherit);
        .button {
          @include Flex(inherit, center, center);
          padding: $mpadding;
          color: $sec_color;
          cursor: pointer;
          &:active {
            @include d-active();
          }
          &:hover {
            color: $primary_color;
          }
        }
        &-right {
          display: flex;
          align-items: flex-start;
        }
      }
      &-content {
        padding: 0 $mpadding $mpadding $mpadding;
      }
    }
    &.mk_head .modal-card-header {
      border-bottom: solid 1px $alto;
    }
    &.mobile-s {
      .modal-card {
        max-width: $mobile_s_width;
      }
    }
    &.mobile-m {
      .modal-card {
        max-width: $mobile_m_width;
      }
    }
    &[lock],
    &[lockapp] {
      * {
        pointer-events: none;
      }
    }
  }
</style>
